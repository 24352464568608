import React from "react";
import { Contract } from "@ethersproject/contracts";
import bscTokenAbi from "./abis/bscTokenAbi.json";
import ethTokenAbi from "./abis/ethTokenAbi.json";
import EthAbi from "./abis/EthAbi.json";
import BscAbi from "./abis/BscAbi.json";
import {
  BscContract,
  bscTokenContract,
  EthContract,
  ethTokenContract,
  walletAddress,
  bscProviderUrl,
  ethProviderUrl
} from "./environment";
import { ethers } from "ethers";

const bscProvider = new ethers.providers.JsonRpcProvider(bscProviderUrl);
const ethProvider = new ethers.providers.JsonRpcProvider(ethProviderUrl);

export const bscVoidAccount = new ethers.VoidSigner(walletAddress, bscProvider);
export const ethVoidAccount = new ethers.VoidSigner(walletAddress, ethProvider);
function useContract(address, ABI, signer) {
  return React.useMemo(() => {
    if (signer) {
      return new Contract(address, ABI, signer);
    } else {
      return new Contract(address, ABI, bscVoidAccount);
    }
  }, [address, ABI, signer]);
}
function useEthContract(address, ABI, signer) {
  return React.useMemo(() => {
    if (signer) {
      return new Contract(address, ABI, signer);
    } else {
      return new Contract(address, ABI, ethVoidAccount);
    }
  }, [address, ABI, signer]);
}

export function useBscTokenContract(signer) {
  return useContract(bscTokenContract, bscTokenAbi, signer);
}
export function useEthTokenContract(signer) {
  return useEthContract(ethTokenContract, ethTokenAbi, signer);
}

export function useEthBridgeContract(signer) {
  return useEthContract(EthContract, EthAbi, signer);
}
export function useBscBridgeContract(signer) {
  return useContract(BscContract, BscAbi, signer);
}
