//Mainnet
// export const bscTokenContract = "0x82190d28E710ea9C029D009FaD951c6F1d803BB3";
// export const BscContract = "0x04841d3A413Dd6885325cea184d4e47Ac8DAe3E1";
// export const ethTokenContract = "0x6c936D4AE98E6d2172dB18c16C4b601C99918EE6";
// export const EthContract = "0xF9Fa750F5A43e3FE7b231Ff7Cd930815a708fC3d";

// export const walletAddress = "0xD776c43E5d8f90FbE2c015eB13c857eA21469764";

// export const bscProviderUrl = "https://bsc-dataseed.binance.org";
// export const ethProviderUrl = "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";

// export const ethChainID = 1;
// export const bscChainID = 56;
// export const network = 'Mainnet';

// export const dynosUrl = "https://life-staking-bridge.herokuapp.com"


// Testnet
export const bscTokenContract = "0x8115360B60524553fC2468B4DC0e2181F1B9d084"; //life
export const BscContract = "0x5E5d9F8ac2C3f314F47CF9B32c0646F06FdB45ac"; //bridge

export const ethTokenContract = "0xF3854BB058503312bb2e25975d239b15c275F174"; //life
export const EthContract = "0x3Daa63c6838b0fc9B7358a140FcDd70dE8bB185A";//bridge

export const walletAddress = "0x3cE3668DFCCAee1a348166B2dF1C88F0D6A1d2Db";
export const bscProviderUrl = "https://data-seed-prebsc-1-s2.binance.org:8545";
export const ethProviderUrl = "https://sepolia.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";


export const ethChainID = 11155111;
export const bscChainID = 97;
export const network = 'Testnet';

export const dynosUrl = "http://localhost:5000"
