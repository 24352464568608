import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Web3Provider } from "@ethersproject/providers";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppContextProvider } from "./utils";

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}
ReactDOM.render(
  <>
    <AppContextProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{ backgroundColor: "#727272" }}
      />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AppContextProvider>
  </>,
  document.getElementById("root")
);
