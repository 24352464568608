import { formatUnits } from "@ethersproject/units";
import React, { useContext, useEffect, useState } from "react";
import { useBscTokenContract, useEthTokenContract } from "../../Hooks";
import { AppContext } from "../../utils";

export default function EthBalance() {
  const { account, signer, web3Provider } = useContext(AppContext);
  const [ethBalance, setethBalance] = useState(0);
  const ethTokenContract = useEthTokenContract();
  const init = async () => {
    try {
      const [ethdecimal, ethbalance] = await Promise.all([
        ethTokenContract.decimals(),
        ethTokenContract.balanceOf(account),
      ]);

      setethBalance((+formatUnits(ethbalance, ethdecimal)).toFixed(4));
      console.log("calling", (+formatUnits(ethbalance, ethdecimal)).toFixed(4));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (account) {
      setInterval(() => {
        init();
      }, 2000);
    }
  }, [account]);
  return <>{ethBalance}</>;
}
