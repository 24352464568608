import Navbar from "./components/Navbar/Navbar";
import { Route, Routes } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import "./App.css";
import NetworkChange from "./NetworkModal";
import Web3 from "web3";
import RouterSwap from "./pages/Bridge/RouterSwap";
import axios from "axios";
import { InjectedConnector } from "@web3-react/injected-connector";
import NetworkModal from "./pages/Bridge/networkModal";
import { Box } from "@material-ui/core";
import { AppContext } from "./utils";

const web3 = new Web3(
  Web3.givenProvider ? Web3.givenProvider : "https://bsc-dataseed.binance.org/"
);

function App() {
  const [switchNetwork, setswitchNetwork] = useState(false);
  const [open, setOpen] = useState(false);

  const { account, chainId, connect } = useContext(AppContext);
  const [selected, setSelected] = useState("");

  const [currentChainId, setCurrentChainId] = useState();

  useEffect(() => {
    if (!account) {
      connect();
    }
    if (chainId) {
      setCurrentChainId(chainId);
    }
  }, [chainId]);
  console.log(currentChainId, "chainId");

  return (
    <>
      <NetworkChange open={switchNetwork} setOpen={setswitchNetwork} />
      <NetworkModal
        setSelected={setSelected}
        open={open}
        setOpen={setOpen}
        setCurrentChainId={setCurrentChainId}
      />
      <Box
        style={{
          background: `url(/assets/bacground.svg)`,
          backgroundSize: "cover",
          backgroundPosition: "50% 1%",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      >
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <RouterSwap
                setOpen={setOpen}
                selected={selected}
                currentChainId={currentChainId}
              />
            }
          />
        </Routes>
      </Box>
    </>
  );
}

export default App;
