import { formatUnits } from "@ethersproject/units";
import React, { useContext, useEffect, useState } from "react";
import { useBscTokenContract } from "../../Hooks";
import { AppContext } from "../../utils";

export default function BscBalance() {
  const { account, signer, web3Provider } = useContext(AppContext);
  const [bscBalance, setbscBalance] = useState(0);
  const bscTokenContract = useBscTokenContract();
  const init = async () => {
    try {
      const [bscdecimal, bscbalance] = await Promise.all([
        bscTokenContract.decimals(),
        bscTokenContract.balanceOf(account),
      ]);

      setbscBalance((+formatUnits(bscbalance, bscdecimal)).toFixed(4));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (account) {
      setInterval(() => {
        init();
      }, 2000);
    }
  }, [account]);
  return <>{bscBalance}</>;
}
