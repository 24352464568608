import React from "react";
import { Typography, Modal, Box, useMediaQuery } from "@material-ui/core";
import BnbTokenImg from "../../assets/bnb-token-icon.png";
import EthereumIcon from "../../assets/ethereum-icon.svg";

import {
  ethChainID,
  bscChainID,
  network
} from '../../environment';

const NetworkModal = ({ setSelected, open, setOpen, setCurrentChainId }) => {
  const matches = useMediaQuery("(max-width: 600px)");

  const handleClose = () => setOpen(false);

  const networkHandler = async (network) => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: network === "bsc" ? "0x" + bscChainID.toString(16).toUpperCase() : "0x" + ethChainID.toString(16).toUpperCase() }],
      });
      setSelected(network);
      setOpen(false);
    } catch (error) {
      setSelected("");
      console.log(error);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "20%",
    backgroundColor: "#1D1D1D",
    border: "2px solid rgba(255, 255, 255, 0.144)",
    borderRadius: "20px",
    boxShadow: 24,
    padding: "30px 20px",
    width: matches ? "83%" : "30%",
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <Typography variant="h5" color="white">
            Switch To
          </Typography>

          <Box
            display="flex"
            flexDirection={{ xs: "row", sm: "row" }}
            mt={3}
            alignItems="center"
            justifyContent="start"
            padding="10px"
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.144)",
              borderRadius: "12px",
              cursor: "pointer",
            }}
            onClick={() => networkHandler("eth")}
          >
            <img
              style={{
                backgroundColor: "white",
                padding: "5px",
                width: matches ? "30px" : "40px",
                borderRadius: "50%",
              }}
              src={EthereumIcon}
              alt="EtheremIcon"
            />
            <Typography
              color="white"
              style={{
                marginLeft: "20px",
                fontSize: matches ? "12px" : "15px",
              }}
            >
              Ethereum {network}
            </Typography>
          </Box>

          <Box
            mt={2}
            display="flex"
            flexDirection={{ sm: "row" }}
            alignItems="center"
            justifyContent="start"
            padding="10px"
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.144)",
              borderRadius: "12px",
              cursor: "pointer",
            }}
            onClick={() => networkHandler("bsc")}
          >
            <img
              src={BnbTokenImg}
              width={matches ? "30px" : "40px"}
              alt="bsc"
            />
            <Typography
              color="white"
              style={{
                marginLeft: "20px",
                fontSize: matches ? "12px" : "15px",
              }}
            >
              BSC {network}
            </Typography>
          </Box>
          <Box
            mt={2}
            display="flex"
            flexDirection={{ sm: "row" }}
            alignItems="center"
            justifyContent="start"
            padding="10px"
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.144)",
              borderRadius: "12px",
              opacity: 0.5,
            }}
          // onClick={() => networkHandler("bsc")}
          >
            <img
              src="/assets/matic.png"
              width={matches ? "30px" : "40px"}
              alt="bsc"
            />
            <Typography
              color="white"
              style={{
                marginLeft: "20px",
                fontSize: matches ? "12px" : "15px",
              }}
            >
              Polygon {network}
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default NetworkModal;
