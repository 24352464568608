import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Slide } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    "& .MuiDialog-container>.MuiDialog-paper": {
      borderRadius: "20px",
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function NetworkChange({ open, setOpen }) {
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };
  const networkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        // params: [{ chainId: "0x38" }],
        params: [{ chainId: "0x61" }],
      });
      // window.location.reload();
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div className="modal__main__container">
        <Dialog
          open={open}
          keepMounted
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          className={classes.container}
        >
          <DialogContent className="dialoge__content__section">
            <Box component="h3" color="#ffff">
              <Box component="span" color="red" fontSize="30px">
                Error!
              </Box>{" "}
              You are on the wrong network, please change to the BSC network and
              try again.{" "}
            </Box>
            <Box align="center" my={2}>
              <button
                style={{
                  background:
                    "radial-gradient(ellipse at bottom,rgba(85, 180, 200, 1),rgba(85, 180, 200, 0))",
                  color: "#23ead3",

                  boxShadow:
                    "1px 1px 0px #43727a inset, 0px 3px 1px -2px rgb(0 0 0 / 20%),0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                  borderRadius: "34px",
                  padding: "15px 20px",
                  border: "1px solid #fff",
                  outline: "none",
                  cursor: "pointer",
                  fontSize: "16px",
                  lineHeight: "19px",
                  fontWeight: "bolder",
                  textTransform: "uppercase",
                  marginRight: "10px",
                }}
                onClick={networkHandler}
              >
                Switch Network
              </button>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

export default NetworkChange;
