import { useWeb3React } from "@web3-react/core";
import React, { useContext, useState } from "react";
import { InjectedConnector } from "@web3-react/injected-connector";
import "./Navbar.css";
import Web3 from "web3";
import { Link } from "react-router-dom";
import {
  Box,
  Container,
  Button,
  Badge,
  useMediaQuery,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import logo from "../../assets/logo.svg";
import { AppContext } from "../../utils";

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const Navbar = () => {
  const matches = useMediaQuery("(max-width: 600px)");

  const [wallet, setWalllet] = useState();
  const { account, connect, disconnect } = useContext(AppContext);

  return (
    <Container>
      <Box py={7} className="nav-bg">
        <Link to="/" className="logo">
          <img
            src="/logo.svg"
            style={{
              width: "60%",
              height: "85%",
            }}
            alt=""
          />
        </Link>
        <div className="nav-links">
          {!account ? (
            <Button
              onClick={() => {
                connect();
              }}
              variant="contained"
              style={{
                background:
                  "radial-gradient(ellipse at bottom,rgba(85, 180, 200, 1),rgba(85, 180, 200, 0))",
                color: "#23ead3",

                boxShadow:
                  "1px 1px 0px #43727a inset, 0px 3px 1px -2px rgb(0 0 0 / 20%),0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                fontSize: matches ? "1rem" : "1.4rem",
                // fontSize={{ xs: "1rem", sm: "3rem" }},
                fontWeight: "700",
                borderRadius: "50px",
                padding: "10px 20px",
                fontFamily: '"Work Sans",sans-serif',
                margin: ".1rem",
              }}
            >
              Connect
            </Button>
          ) : (
            <Button
              onClick={() => {
                disconnect();
              }}
              variant="contained"
              style={{
                background:
                  "radial-gradient(ellipse at bottom,rgba(85, 180, 200, 1),rgba(85, 180, 200, 0))",
                color: "#23ead3",

                boxShadow:
                  "1px 1px 0px #43727a inset, 0px 3px 1px -2px rgb(0 0 0 / 20%),0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                fontSize: matches ? "1rem" : "1.4rem",
                // fontSize={{ xs: "1rem", sm: "3rem" }},
                fontWeight: "700",
                borderRadius: "50px",
                padding: "10px 20px",
                fontFamily: '"Work Sans",sans-serif',
                margin: ".1rem",
              }}
            >
              {account.slice(0, 6) + "..." + account.slice(-5)}{" "}
              <StyledBadge
                style={{
                  paddingLeft: "10px",
                }}
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
              />
            </Button>
          )}
        </div>
      </Box>
    </Container>
  );
};

export default Navbar;
