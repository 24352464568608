import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Button,
  useMediaQuery,
  Collapse,
} from "@material-ui/core";
import {
  ethChainID,
  bscChainID,
  network
} from "../../environment";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {
  useEthTokenContract,
  useBscTokenContract,
  useEthBridgeContract,
  useBscBridgeContract,
} from "./../../Hooks";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import bsc from "../../assets/bnb-token-icon.png";
import eth from "../../assets/ethereum-icon.svg";
import { AppContext, gasEstimationForAll, url } from "../../utils";
import { formatUnits, parseUnits } from "@ethersproject/units";
import Loading from "./loading";
import axios from "axios";
import { BscContract, EthContract } from "../../environment";
import BscBalance from "./bscBalance";
import EthBalance from "./ethBalance";

function RouterSwap({ setOpen, currentChainId }) {
  const matches = useMediaQuery("(max-width: 600px)");
  const [amount, setamount] = useState();
  const { account, signer, web3Provider } = useContext(AppContext);
  const [bscBalance, setbscBalance] = useState(0);
  const [ethBalance, setethBalance] = useState(0);
  const ethTokenContract = useEthTokenContract();
  const ethTokenContract1 = useEthTokenContract(signer);
  const bscTokenContract = useBscTokenContract();
  const bscTokenContract1 = useBscTokenContract(signer);
  const ethBridgeContract = useEthBridgeContract(signer);
  const ethBridgeContract1 = useEthBridgeContract();
  const bscBridgeContract = useBscBridgeContract(signer);
  const bscBridgeContract1 = useBscBridgeContract();

  const [loading, setloading] = useState(false);
  const [openAlert, setopenAlert] = useState(false);
  const [txHash, settxHash] = useState("");

  const [taxFeeBsc, settaxFeeBsc] = useState(0);
  const [taxFeeEth, settaxFeeEth] = useState(0);
  const init = async () => {
    try {
      const [
        bscdecimal,
        ethdecimal,
        bscbalance,
        ethbalance,
        taxfeeBsc,
        taxfeeEth,
      ] = await Promise.all([
        bscTokenContract.decimals(),
        ethTokenContract.decimals(),
        bscTokenContract.balanceOf(account),
        ethTokenContract.balanceOf(account),
        bscBridgeContract1.taxfee(),
        ethBridgeContract1.taxfee(),
      ]);
      console.log(+taxfeeBsc, "taxfeeBsc");
      setbscBalance(+formatUnits(bscbalance, bscdecimal));
      settaxFeeBsc(+taxfeeBsc);
      settaxFeeEth(+taxfeeEth);
      setethBalance(+formatUnits(ethbalance, ethdecimal));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(async () => {
    if (account) {
      init();
    }
  }, [account, currentChainId]);

  const swapHandler = async () => {
    console.log('New Swapping..')
    if (!account) {
      toast.error("Please connect to wallet");
    } else if (currentChainId !== bscChainID && currentChainId !== ethChainID) {
      toast.error("you are on wrong network.");
    } else if (!amount) {
      toast.error("Please enter amount");
    } else if (isNaN(amount) || +amount <= 0) {
      toast.error("Please enter valid amount");
    } else if (currentChainId === bscChainID && amount <= taxFeeBsc) {
      toast.error("amount should be greater than tax fee");
    } else if (currentChainId === ethChainID && amount <= taxFeeEth) {
      toast.error("amount should be greater than tax fee");
    } else {
      try {
        setloading(true);
        const [
          bscdecimal,
          ethdecimal,
          ethbalance,
          ethallowance,
          bscbalance,
          bscallowance,
        ] = await Promise.all([
          bscTokenContract.decimals(),
          ethTokenContract.decimals(),
          ethTokenContract.balanceOf(account),
          ethTokenContract.allowance(account, EthContract),
          bscTokenContract.balanceOf(account),
          bscTokenContract.allowance(account, BscContract),
        ]);
        if (currentChainId === ethChainID) {
          console.log('allowance: ', ethallowance)
          if (+ethallowance < +amount) {
            let data = [EthContract, ethbalance];
            let fn = ethTokenContract1.estimateGas.approve;
            let tx = await ethTokenContract1.approve(...data, {
              gasLimit: gasEstimationForAll(account, fn, data),
            });
            await tx.wait();
          }
          let data = [parseUnits(amount, ethdecimal)];

          let fn = ethBridgeContract.estimateGas.burn;
          const tx = await ethBridgeContract.burn(...data, {
            gasLimit: gasEstimationForAll(account, fn, data),
          });
          console.log('Transaction: ', tx);
          let serverData = {
            to: account,
            amount: parseUnits((+amount - +taxFeeEth).toString(), bscdecimal),
            transaction: tx,
          };

          console.log('Server Request Body: ', JSON.stringify(serverData))
          const {
            data: { txhash },
          } = await axios.post(url + "/send-transaction-eth", serverData);
          console.log(txhash, "txhash");
          setopenAlert(true);
          settxHash(txhash.hash);
          await tx.wait();
          toast.success("Success!");
        } else if (currentChainId === bscChainID) {
          if (+bscallowance < +amount) {
            let data = [BscContract, bscbalance];
            let fn = bscTokenContract1.estimateGas.approve;
            let tx = await bscTokenContract1.approve(...data, {
              gasLimit: gasEstimationForAll(account, fn, data),
            });
            await tx.wait();
            console.log({ tx })
          } else console.log('already allowed', { bscallowance, amount })

          let data = [parseUnits(amount, bscdecimal)];

          let fn = bscBridgeContract.estimateGas.burn;
          const tx = await bscBridgeContract.burn(...data, {
            gasLimit: gasEstimationForAll(account, fn, data),
          });
          let serverData = {
            to: account,
            amount: parseUnits((+amount - +taxFeeBsc).toString(), ethdecimal),
            transaction: tx,
          };
          const {
            data: { txhash },
          } = await axios.post(url + "/send-transaction-bsc", serverData);
          console.log(txhash, "txhash");
          setopenAlert(true);
          settxHash(txhash.hash);
          await tx.wait();
          toast.success("Success!");
        } else {
          toast.error("You are connected to wrong network");
        }
        init();
        setloading(false);
      } catch (error) {
        console.log({ error })
        if (error?.data?.message) {
          toast.error(error?.data?.message);
        } else {
          toast.error(error?.message);
        }
        setloading(false);
      }
    }
  };
  const networkHandler = async (network) => {
    console.log(network, "network");
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: network === "bsc" ? "0x" + bscChainID.toString(16).toUpperCase() : "0x" + ethChainID.toString(16).toUpperCase() }],
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box py={8}>
      <Loading loading={loading} />
      <Container maxWidth="md">
        <Box
          mt={4}
          bgcolor="rgba(20, 23, 42, 0.3)"
          p={{ xs: 2, sm: 4 }}
          borderRadius="30px"
          padding="2rem"
        >
          <Box
            borderRadius="20px"
            bgcolor="rgba(255, 255, 255, 0.144)"
            p={{ xs: 2, sm: 5 }}
          >
            <Box display="flex" justifyContent="space-between" mb={3}>
              <Box fontSize="14px" color="#fff">
                From
              </Box>

              <Box fontSize="14px" color="#fff">
                Balance:{" "}
                {currentChainId === ethChainID ? <EthBalance /> : <BscBalance />}
              </Box>
            </Box>

            <Grid
              container
              spacing={4}
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Grid item xs={12} sm={11} md={5}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  borderBottom="1px solid #fff"
                >
                  <input
                    value={amount}
                    onChange={(e) => setamount(e.target.value)}
                    type="number"
                    pattern="^[0-9]*[.,]?[0-9]*$"
                    placeholder={"0.0"}
                    spellCheck="false"
                    style={{
                      backgroundColor: "transparent",
                      outline: "none",
                      border: "none",
                      fontSize: "28px",
                      fontWight: 600,
                      width: "100%",
                      color: "#FFF",
                      flexBasis: "100%",
                      paddingBottom: "5px",
                    }}
                  />

                  <Button
                    onClick={() => {
                      if (currentChainId === ethChainID) {
                        setamount(ethBalance.toFixed(4));
                      } else if (currentChainId === bscChainID) {
                        setamount(bscBalance.toFixed(4));
                      }
                    }}
                    variant="outlined"
                    style={{
                      fontSize: "14px",
                      color: "#fff",
                      border: "1px solid #fff",
                      padding: matches ? "3px 5px" : "5px 7px",
                      minWidth: "40px",
                      marginBottom: "3px",
                    }}
                  >
                    MAX
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={11} md={7}>
                <Box display="flex" justifyContent={"center"}>
                  <Button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #fff",
                      padding: "13px 20px",
                      borderRadius: "14px",
                      "&:focus": {
                        borderColor: "silver",
                        boxShadow:
                          " 0px 0px 23px 2px rgba(255, 255, 255, 0.144)",
                      },
                    }}
                    onClick={() => setOpen(true)}
                  >
                    {currentChainId === bscChainID || currentChainId === ethChainID ? (
                      <>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width={{ xs: "30px", sm: "40px" }}
                          height={{ xs: "30px", sm: "40px" }}
                          bgcolor="#fff"
                          borderRadius="50%"
                          mr={1}
                        >
                          <img
                            width={matches ? "20px" : "25px"}
                            src={currentChainId === ethChainID ? eth : bsc}
                            alt=""
                          />
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          mb={1}
                        >
                          <Box
                            fontSize={{ xs: "0.8rem", sm: "1rem" }}
                            fontWeight={500}
                          >
                            {currentChainId === ethChainID ? "Eth" : "BSC"}
                          </Box>
                          <Box
                            lineHeight="1px"
                            fontSize={{ xs: "12px", sm: "14px" }}
                            fontWeight={400}
                            style={{ textTransform: "lowercase" }}
                          >
                            {network}
                          </Box>
                        </Box>

                        <Box
                          bgcolor="rgba(255, 255, 255, 0.144)"
                          ml={4}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width={{ xs: "25px", sm: "30px" }}
                          height={{ xs: "25px", sm: "30px" }}
                          borderRadius="50%"
                        >
                          <ArrowDropDownOutlinedIcon />
                        </Box>
                      </>
                    ) : (
                      <Box color="red" fontSize="20px">
                        ! Wrong network
                      </Box>
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box fontSize={{ xs: "12px", sm: "14px" }} mt={0.5}>
            Transaction Fee: {currentChainId === bscChainID ? taxFeeBsc : +taxFeeEth} in
            LIFE
          </Box>
          <Grid item container xs={12} style={{ margin: "30px 0px" }}>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginLeft: "20px",
              }}
            >
              <Box
                bgcolor="rgba(20, 23, 42, 0.3)"
                width="40px"
                height="40px"
                borderRadius="50%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  if (currentChainId === ethChainID) {
                    networkHandler("bsc");
                  } else if (currentChainId === bscChainID) {
                    networkHandler("eth");
                  }
                }}
              >
                <ArrowDownwardIcon
                  sx={{
                    color: "white",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Box
            borderRadius="20px"
            bgcolor="rgba(255, 255, 255, 0.144)"
            p={{ xs: 2, sm: 5 }}
          >
            <Box display="flex" justifyContent="space-between" mb={3}>
              <Box fontSize="14px" color="#fff">
                To
              </Box>
              <Box fontSize="14px" color="#fff">
                Balance:{" "}
                {currentChainId === ethChainID ? <BscBalance /> : <EthBalance />}
              </Box>
            </Box>

            <Grid
              container
              spacing={4}
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Grid item xs={12} sm={11} md={5}>
                <Box>
                  {currentChainId === bscChainID ? (
                    <input
                      value={+amount > +taxFeeBsc ? +amount - taxFeeBsc : "0"}
                      // universal input options
                      readOnly
                      inputMode="decimal"
                      title="Token Amount"
                      autoComplete="off"
                      autoCorrect="off"
                      // text-specific options
                      type="text"
                      pattern="^[0-9]*[.,]?[0-9]*$"
                      placeholder={"0.0"}
                      minLength={1}
                      maxLength={79}
                      spellCheck="false"
                      style={{
                        backgroundColor: "transparent",
                        outline: "none",
                        border: "none",
                        fontSize: "28px",
                        fontWight: 600,
                        width: "100%",
                        color: "#FFF",
                        flexBasis: 2,
                        paddingBottom: "5px",
                        borderBottom: "1px solid #fff",
                      }}
                    />
                  ) : (
                    <input
                      value={+amount > +taxFeeEth ? +amount - +taxFeeEth : "0"}
                      // universal input options
                      readOnly
                      inputMode="decimal"
                      title="Token Amount"
                      autoComplete="off"
                      autoCorrect="off"
                      // text-specific options
                      type="text"
                      pattern="^[0-9]*[.,]?[0-9]*$"
                      placeholder={"0.0"}
                      minLength={1}
                      maxLength={79}
                      spellCheck="false"
                      style={{
                        backgroundColor: "transparent",
                        outline: "none",
                        border: "none",
                        fontSize: "28px",
                        fontWight: 600,
                        width: "100%",
                        color: "#FFF",
                        flexBasis: 2,
                        paddingBottom: "5px",
                        borderBottom: "1px solid #fff",
                      }}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={11} md={7}>
                <Box display="flex" justifyContent={"center"}>
                  <Button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid silver",
                      padding: "13px 20px",
                      borderRadius: "14px",
                      "&:hover": { border: "1px solid black" },
                    }}
                    onClick={() => setOpen(true)}
                  >
                    {currentChainId === bscChainID || currentChainId === ethChainID ? (
                      <>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width={{ xs: "30px", sm: "40px" }}
                          height={{ xs: "25px", sm: "40px" }}
                          bgcolor="#fff"
                          borderRadius="50%"
                          mr={1}
                        >
                          <img
                            width={matches ? "20px" : "25px"}
                            src={currentChainId === ethChainID ? bsc : eth}
                            alt=""
                          />
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          mb={1}
                        >
                          <Box
                            fontSize={{ xs: "0.8rem", sm: "1rem" }}
                            fontWeight={600}
                          >
                            {currentChainId === ethChainID ? "BSC" : "Eth"}
                          </Box>
                          <Box
                            lineHeight="1px"
                            fontSize={{ xs: "12px", sm: "14px" }}
                            fontWeight={400}
                            style={{ textTransform: "lowercase" }}
                          >
                            {network}
                          </Box>
                        </Box>

                        <Box
                          bgcolor="rgba(255, 255, 255, 0.144)"
                          ml={currentChainId === 1 ? 4 : 2}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width={{ xs: "25px", sm: "30px" }}
                          height={{ xs: "25px", sm: "30px" }}
                          borderRadius="50%"
                        >
                          <ArrowDropDownOutlinedIcon />
                        </Box>
                      </>
                    ) : (
                      <Box color="red" fontSize="20px">
                        ! Wrong network
                      </Box>
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Collapse in={openAlert}>
          <Alert
            style={{
              background: "rgba(255, 255, 255, 0.144)",
              borderRadius: "10px",
              margin: "10px 0px",
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setopenAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Success! Your transaction has been sent.
            <br />
            <a
              component="a"
              target="_blank"
              href={
                currentChainId === ethChainID
                  ? "https://bscscan.com/tx/" + txHash
                  : "https://etherscan.io/tx/" + txHash
              }
            >
              <Box
                fontSize="14px"
                color="#fff"
                display="flex"
                alignItems="center"
              >
                Open Transaction <OpenInNewIcon style={{ marginLeft: "7px" }} />
              </Box>
            </a>
          </Alert>
        </Collapse>
        {/* Action Button */}
        <Box my={8} display="flex" justifyContent="center">
          {account ? (
            <>
              <Button
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.144)",
                  borderRadius: "20px",
                  padding: "13px 0px",
                  width: matches ? "50%" : "35%",
                  cursor: "pointer",
                }}
                onClick={swapHandler}
              >
                Swap
              </Button>
            </>
          ) : (
            <Button
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.144)",
                borderRadius: "20px",
                padding: "13px 0px",
                width: matches ? "50%" : "35%",
              }}
              onClick={() => toast.error("Connect to your Wallet.")}
            >
              Connect to Wallet
            </Button>
          )}
        </Box>
      </Container>
    </Box>
  );
}

export default RouterSwap;
